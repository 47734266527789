
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  OPTION_BUSINESS_TYPE,
  JOB_OPTION_TYPE,
  COMMERCE_OPTION_ITEMS_DESCRIPTION,
  STEP_OPTION_ITEM_CONFIG,
} from "@/shared/interface/newItem";
import { tsService } from "@/shared/services/api/trouble-shooting";
import { tsGraphQLService } from "@/shared/services/api/trouble-shooting-graphQL";
import { util } from "@/shared/services/util";
import "./ParameterArea.scss";

import cloneDeep from "lodash.clonedeep";
import {
  from,
  debounceTime,
  BehaviorSubject,
  switchMap,
  Observable,
  of,
} from "rxjs";
import { GET_CASE_BY_ID } from "@/shared/services/graphql-action";
import { ITimeZone, TimeUtil } from "@/shared/utils/TimeUtils";
import { ParameterAreaUtils } from "@/shared/utils/ParameterAreaUtils";
const X2JS = require("x2js");
const x2js = new X2JS({ keepCData: true });

type resourceSearchParams = {
  value: string;
  index?: number;
};

@Component({
  name: "ParameterAreaComponent",
})
export default class ParameterAreaComponent extends Vue {
  @Prop({ default: false }) currentContentDefinition?: string;
  @Prop({ default: false }) currentExecutor?: string;
  @Prop({ default: false }) disabled;
  @Prop({ default: false }) currentContent?: any;

  optionArray: Array<any> = [];
  searchOptionsArray: any[] = [];
  multiselectOffset = 0;
  multiSelectLoadingBusy = false;
  loading = false;
  totalPages = 0;
  searchString = "";
  searchStrObs$ = new BehaviorSubject<resourceSearchParams>({ value: "" });
  resourceType: string[][] = [["Microsoft.ContainerService/managedClusters"]];
  resourceTypeList = ParameterAreaUtils.resourceTypeList;
  JOB_OPTION_TYPE = JOB_OPTION_TYPE;
  COMMERCE_OPTION_ITEMS_DESCRIPTION = COMMERCE_OPTION_ITEMS_DESCRIPTION;
  paramForm: any = {
    optionArray: [],
  };

  dateComponentInfo: any = {
    daterange: {
      startPlaceholder: "Start date",
      endPlaceholder: "End date",
      placeholder: "",
      type: "daterange",
    },
    datetimerange: {
      startPlaceholder: "Start time",
      endPlaceholder: "End time",
      placeholder: "",
      type: "datetimerange",
    },
    date: {
      startPlaceholder: "",
      endPlaceholder: "",
      placeholder: "Select date",
      type: "date",
    },
    datetime: {
      startPlaceholder: "",
      endPlaceholder: "",
      placeholder: "Select time",
      type: "datetime",
    },
  };

  dateTypeFormat: any = ["HH", "mm", "ss"];
  browserTimeZone: any =
    Intl.DateTimeFormat().resolvedOptions().timeZone || "Browser Time";
  timezonelist: ITimeZone[];
  timezoneOffset: number = -new Date().getTimezoneOffset() / 60;
  offset: any =
    this.timezoneOffset < 0 ? this.timezoneOffset : "+" + this.timezoneOffset;
  rules = {};
  currentFileOptionIndex: any = null;
  allOptionsAreHidden = false;
  workItemParams: any = null;

  @Watch("currentContentDefinition", { deep: true, immediate: true })
  OnContentDefinitionChange(val: string): void {
    if (!val) {
      this.$message.error("The content doesn't exist on the target executor.");
      return;
    }
    let jobDefinitionJSON = x2js.xml2js(atob(val));
    if (jobDefinitionJSON.joblist?.job?.context?.options?.option) {
      const paramfromJobDefinition =
        jobDefinitionJSON.joblist.job.context.options.option;

      if (Array.isArray(jobDefinitionJSON.joblist.job.context.options.option)) {
        this.paramForm.optionArray = paramfromJobDefinition;
      } else if (jobDefinitionJSON.joblist.job.context.options.option) {
        this.paramForm.optionArray = [paramfromJobDefinition];
      }
    }
    if (this.paramForm.optionArray.length > 0) {
      this.setOptionType();
      this.setOptionValidation();
    }
  }
  @Watch("paramForm.optionArray", { deep: true, immediate: true })
  OnOptionArrayChange(): void {
    this.$emit("setNextButtonOfParameter");
  }

  @Watch("currentContent", { deep: true, immediate: true })
  OnCurrentContentChanged(): void {
    if (this.currentContent?.id) {
      this.$apollo
        .query({
          query: GET_CASE_BY_ID,
          variables: {
            id: this.$route?.query?.caseId, // need to get workitem id from route query
          },
        })
        .then((res) => {
          const data = res.data.hybris_workspace_item;
          this.workItemParams = JSON.parse(data[0].parameters);
          const isWorkItemParamPrefilled = this.$route?.query?.clone.toString();
          this.paramForm.optionArray =
            ParameterAreaUtils.setParamValuesinParamOptionArray(
              isWorkItemParamPrefilled,
              this.paramForm.optionArray,
              this.workItemParams
            );
        });
    }
  }

  // @Watch("resourceType")
  // onResourceTypeChange(val: string[]): void {
  //   this.resourceTypeList = this.resourceTypeList.map((item) => {
  //     item.disabled = false;
  //     return item;
  //   });
  // }

  created(): void {
    this.timezonelist = TimeUtil.getListofTimeZones();
    this.getCustomerResourceData();
  }

  /**
   * computes single or multiple options selector based on allowed resource types from runbook definition
   * @returns function
   */
  get isMultiple(): any {
    return (option) => {
      let allowedResourceNum = option?._allowedResourceNum;
      //undefined for old runbooks, allowedResourceNum 1 for multiple options select and
      // allowedResourceNum 1 for multiple options select (IOP-1961)
      if (allowedResourceNum === undefined || allowedResourceNum === "1")
        return true;
      return false;
    };
  }

  /**
   * computes warning text to be displayed for customer resource selection based on conditions
   * @returns function
   */
  get warningText(): any {
    return (option) => {
      let warningMsg = "no data";
      let allowedResourceTypes = option?._allowedResourceTypes;
      if (allowedResourceTypes && option.value.length > 0) {
        // For the new runbooks with allowed Resource Types value
        let allowedResourcesCount = allowedResourceTypes.split(",").length;
        if (allowedResourcesCount === 1 && option.value.length >= 1)
          warningMsg = "the number of chose resources reaches the maximum 1";
        else if (allowedResourcesCount >= 1 && option.value.length >= 10)
          warningMsg = "the number of chose resources reaches the maximum 10";
      } else {
        // For the older runbooks without the allowed Resource Types value
        warningMsg =
          option?.value.length >= 10
            ? "the number of chose resources reaches the maximum 10"
            : "no data";
      }
      return warningMsg;
    };
  }

  /**
   * set the type of options according to description for commerce specific options
   * @returns void
   */
  setOptionTypeForCommerceOptions(item: any): void {
    if (
      item.description.includes("DTU") ||
      item.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.ccv2DBs
    ) {
      item.type = JOB_OPTION_TYPE.cascader;
      return;
    }
    switch (item.description) {
      case COMMERCE_OPTION_ITEMS_DESCRIPTION.futureDate:
        item.type = JOB_OPTION_TYPE.date;
        break;
      case COMMERCE_OPTION_ITEMS_DESCRIPTION.subscriptions:
        item.type = JOB_OPTION_TYPE.select;
        break;
      case COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResources:
      case COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResourcesNames:
        item.type = JOB_OPTION_TYPE.multiselect;
        break;
      case COMMERCE_OPTION_ITEMS_DESCRIPTION.datewithTz:
      case COMMERCE_OPTION_ITEMS_DESCRIPTION.dateRangewithTz:
        item.type = JOB_OPTION_TYPE.date;
        item.timeZone = "";
        this.setDefaultTimeZone(item);
        break;
      default:
        item.type = JOB_OPTION_TYPE.commerce;
        break;
    }
  }

  /**
   * set the default timezone during component creation and when the user clears timezone selection
   * @returns void
   */

  setDefaultTimeZone(item: any): void {
    if (item.timeZone?.length === 0) {
      item.timeZone = Intl.DateTimeFormat("default", {
        timeZoneName: "short",
        timeZone: "UTC",
      })
        .formatToParts()
        .find((i) => i.type === "timeZoneName")?.value;
    }
  }

  /**
   * set the type of options according to 3 fields: _values, _valuesUrl, _multivalued
   * @returns void
   */
  setOptionType(): void {
    let count = 0;
    this.paramForm.optionArray.forEach((item, index) => {
      if (
        !!item.description &&
        item.description.includes(OPTION_BUSINESS_TYPE.Commerce)
      ) {
        this.setOptionTypeForCommerceOptions(item);
      } else if (item._secure) {
        item.type = JOB_OPTION_TYPE.secure;
      } else if (!!item._values || !!item._valuesUrl) {
        item.type = JOB_OPTION_TYPE.select;
      } else if (item._type === "file") {
        item.type = JOB_OPTION_TYPE.file;
      } else {
        if (item._multivalued === "true") {
          item.type = JOB_OPTION_TYPE.tag;
        } else if (item.isDate === "true") {
          item.type = JOB_OPTION_TYPE.date;
        } else {
          item.type = JOB_OPTION_TYPE.input;
        }
      }
      if (item.hidden === "true") {
        count++;
      }
      this.setOptionConfig(item, index);
    });
    this.allOptionsAreHidden =
      !!count && count === this.paramForm.optionArray.length ? true : false;
    this.paramForm.optionArray = cloneDeep(this.paramForm.optionArray);
  }

  /**
   * sthe validation od options
   * @returns void
   */
  setOptionValidation(): void {
    this.paramForm.optionArray.forEach((o) => {
      this.rules[o._name] = [];
      if (o._regex) {
        let regex = new RegExp(o._regex);
        let regexValidate = (rule: any, value: string, callback: any) => {
          if (!regex.test(value)) {
            callback(
              new Error("Value does not match the regular expression:" + regex)
            );
          } else {
            callback();
          }
        };
        this.rules[o._name].push({
          validator: regexValidate,
          trigger: "change",
        });
      }
      if (o._required === "true") {
        if (o.type === JOB_OPTION_TYPE.secure) {
          let secureValidate = (rule: any, value: string, callback: any) => {
            if (!value && !o._storagePath) {
              callback(new Error("can't be empty"));
            } else {
              callback();
            }
          };
          this.rules[o._name].push({
            required: true,
            validator: secureValidate,
            trigger: ["change", "blur"],
          });
        } else if (o.type === JOB_OPTION_TYPE.file) {
          let fileValidate = (rule: any, value: string, callback: any) => {
            if (!value || value.length < 1) {
              callback(new Error("Upload 1 file for this option"));
            } else {
              callback();
            }
          };
          this.rules[o._name].push({
            required: true,
            validator: fileValidate,
            trigger: "change",
          });
        } else if (o.type === JOB_OPTION_TYPE.date) {
          let dateValidate = (rule: any, value: string, callback: any) => {
            if (!value || value.length < 1) {
              callback(new Error("can't be empty"));
            } else {
              callback();
            }
          };
          this.rules[o._name].push({
            required: true,
            validator: dateValidate,
            trigger: "change",
          });
        } else {
          this.rules[o._name].push({
            required: true,
            message: "can't be empty",
            trigger: ["change", "blur"],
          });
        }
      }
      if (o.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.futureDate) {
        const checkFutureTimeValidate = (
          rule: any,
          value: any,
          callback: any
        ) => {
          if (!value || (value.length && value.length === 0)) {
            callback();
            return;
          }
          const selectedDate =
            value.length && value.length > 0
              ? new Date(value[0])
              : new Date(value);
          if (selectedDate < new Date()) {
            callback(new Error("Must select a future time."));
          } else {
            callback();
          }
        };
        this.rules[o._name].push({
          validator: checkFutureTimeValidate,
          trigger: "change",
        });
      }
    });
  }

  /**
   * Sets new option config according to origin configs
   * @param {Object} option: current option
   */
  setOptionConfig(option: STEP_OPTION_ITEM_CONFIG, index: number): void {
    switch (option.type) {
      case JOB_OPTION_TYPE.select: {
        if (
          option._valuesUrl ||
          option.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.subscriptions
        ) {
          this.getOptionValues(option, index);
        } else {
          option.values = option._values.split(",");
        }
        option.multivalued = option._multivalued === "true" ? true : false;
        if (option.multivalued) {
          option.value =
            option._value && option._value.split(",").length > 0
              ? option._value.split(",")
              : [];
        } else {
          option.value = option._value
            ? option._value
            : option._enforcedvalues === "true" && option._required === "true"
            ? undefined
            : option.values[0]
            ? option.values[0]
            : undefined;
        }

        option.addTag = option._enforcedvalues === "true" ? false : true;
        break;
      }
      case JOB_OPTION_TYPE.tag: {
        option.multivalued = true;
        option.value =
          !!option._value && option._value.split(",").length > 0
            ? option._value.split(",")
            : [];
        option.values = [];
        option.addTag = true;
        option.placeholder = "Press the enter key to add tag";
        break;
      }
      case JOB_OPTION_TYPE.input: {
        option.multivalued = false;
        option.value = option._value ? option._value : "";
        if (option._name === "CONSUMER_EMAIL") {
          option.value = this.$store.state.userEmail;
        }
        option.inputType = "text";
        break;
      }
      case JOB_OPTION_TYPE.secure: {
        option.multivalued = false;
        option.value = option._value ? option._value : "";
        option.inputType = option.hidden === "true" ? "text" : "password";
        break;
      }
      case JOB_OPTION_TYPE.file: {
        option.fileList = [];
        option.disabled = false;
        break;
      }
      case JOB_OPTION_TYPE.commerce: {
        option.multivalued = option._multivalued === "true" ? true : false;
        option.value = [];
        option.values = [];
        option.isLoading = false;
        option.addTag = false;
        this.getCommerceOptionValues(option, index);
        break;
      }
      case JOB_OPTION_TYPE.date: {
        option.multivalued = false;
        option.datetype = this.isShowTime(option.dateFormat, "type");
        option.dateFormat = option.dateFormat.replace("(range)", "");
        option.value = ParameterAreaUtils.setDateValue(option);
        option.pickerOptions = {};
        option.defaultTime = null;
        if (
          option.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.futureDate
        ) {
          if (option.datetype === "datetime")
            option.defaultTime = util.roundToNextHour();
          const current =
            option.datetype === "daterange" || option.datetype === "date"
              ? Date.now()
              : Date.now() - 8.64e7;
          option.pickerOptions = {
            disabledDate: (selectTime) => {
              return selectTime.getTime() < current;
            },
          };
        }
        break;
      }
      case JOB_OPTION_TYPE.cascader: {
        option.value = [];
        option.values = [];
        option.isLoading = false;
        option.addTag = false;
        this.getCascaderOptionValues(option, index);
        break;
      }
      case JOB_OPTION_TYPE.multiselect: {
        if (
          option.description ===
            COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResources ||
          option.description ===
            COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResourcesNames
        ) {
          this.paramForm.optionArray[index].placeholder =
            "Search Azure environments (Min 3 chars)";
          this.paramForm.optionArray[index].value = [];
          if (
            option?._defaultResourceType !== undefined &&
            option?._defaultResourceType !== ""
          ) {
            this.resourceType[index] = [option?._defaultResourceType];
          }
        }
      }
    }
  }

  /**
   * Gets option values if the option has remote url
   * @param {Object} option:current option
   * @param {number} index: the index of current option
   * if the remote url equals to environment.rundeckDevBaseUrl, it's a option of simple job
   * if not, it's advanced job
   */
  getOptionValues(option: STEP_OPTION_ITEM_CONFIG, index: number): void {
    option.isLoading = true;
    option.values = [];
    if (
      option.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.subscriptions
    ) {
      tsGraphQLService.getCustomerSubscriptionsInfoList().then(
        (res) => {
          if (res.data) {
            this.paramForm.optionArray[index].values =
              res.data?.commerce_customer_subscription?.map(
                (item) => item.azure_subscription_id
              );
            this.paramForm.optionArray[index].isLoading = false;
          }
        },
        (error) => {
          console.log(error);
          this.paramForm.optionArray[index].isLoading = false;
        }
      );
    } else {
      tsService.getOptionValueByURL(option._valuesUrl).then(
        (res) => {
          if (res.data) {
            this.paramForm.optionArray[index].values = Object.keys(res.data);
          }
          this.paramForm.optionArray[index].isLoading = false;
        },
        (error) => {
          console.log(error);
          this.paramForm.optionArray[index].isLoading = false;
        }
      );
    }
  }

  /**
   * get all the nodes of ccv1
   * @param {Object} option: current option object
   * @returns void
   */
  getCommerceOptionValues(
    option: STEP_OPTION_ITEM_CONFIG,
    index: number
  ): void {
    option.isLoading = true;
    option.values = [];
    let fn: any;
    if (option.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.ccv2DBMini) {
      fn = tsService.getCCV2AllDBMinimum();
    } else {
      option.isLoading = false;
      return;
    }
    fn.subscribe(
      (data) => {
        this.paramForm.optionArray[index].values = data;
        this.paramForm.optionArray[index].isLoading = false;
      },
      (error) => {
        console.log(error);
        this.paramForm.optionArray[index].isLoading = false;
      }
    );
  }
  /**
   * get values of cacader
   * @param {Object} option: current option object
   * @param {number} indedx: current option index
   * @returns void
   */
  getCascaderOptionValues(
    option: STEP_OPTION_ITEM_CONFIG,
    index: number
  ): void {
    option.values = [];
    option.showAllLevels = true;
    let treeLevel = 3;
    if (
      typeof option.description === "string" &&
      option.description.includes("DTU")
    ) {
      option.isLoading = true;
      option.props = {
        label: "name",
        value: "code",
        placeholder: "",
      };
      const typeName = "DTU_Tier";
      treeLevel =
        option.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.dtuTierMini
          ? 2
          : 3;
      tsGraphQLService.getReferenceData(typeName, treeLevel).subscribe(
        (res) => {
          if (!res.data.hybris_workspace_reference_data_type[0].data) {
            this.$message.error(`Can't get option values.`);
            return;
          }
          let transformedData = ParameterAreaUtils.transformReferenceData(
            res,
            treeLevel
          );
          this.paramForm.optionArray[index].values = transformedData.data;
          this.paramForm.optionArray[index].value =
            transformedData.defaultValue;
          this.paramForm.optionArray[index].isLoading = false;
        },
        (error) => {
          console.log(error);
          this.paramForm.optionArray[index].isLoading = false;
          this.$message.error(`Can't get option values.`);
        }
      );
    } else if (
      option.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.ccv2DBs
    ) {
      const vm = this;
      option.showAllLevels = false;
      option.isLoading = true;
      option.props = {
        placeholder: "",
        lazy: true,
        lazyLoad(node, resolve) {
          let params = {
            tname: "",
            sid: "",
            sname: "",
          };
          const { level } = node;
          if (level === 0) {
            params.tname = "*";
          } else if (level === 1) {
            params.sid = node.value;
          } else if (level === 2) {
            params.sname = node.value;
          }
          tsService
            .getCCV2AllDBs(params.tname, params.sid, params.sname)
            .subscribe(
              (res) => {
                resolve(res);
                vm.paramForm.optionArray[index].isLoading = false;
              },
              (error) => {
                console.log(error);
                vm.paramForm.optionArray[index].isLoading = false;
              }
            );
        },
      };
    }
  }

  /**
   * add the tag value to option
   * @param {string} newTag: the current tag content
   * @param { Object} option: current option
   * @returns void
   */
  addTagFn(newTag: string, option: STEP_OPTION_ITEM_CONFIG): void {
    option.values.push(newTag);
    option.value.push(newTag);
  }

  /**
   * Show Warnning message when select one more files for an option
   * @returns void
   */
  handleExceedFiles(): void {
    this.$message.warning(`You can only upload 1 file for this option.`);
  }

  /**
   * bind the option value with validation
   * @param {Object} option: current option object
   * @param {number} index: the index number of current option
   * @return {string} the bind value
   */
  setPropValue(option: STEP_OPTION_ITEM_CONFIG, index: number): string {
    if (option.type !== JOB_OPTION_TYPE.file) {
      return "optionArray." + index + ".value";
    } else {
      return "optionArray." + index + ".fileList";
    }
  }

  /**
   * change the option.fileList value when uploading a file
   * @param {any} file: default parameter, the current handled file
   * @param {any} fileList: default paramter, the uploaded file list
   * @param {any} option: current option object (file option)
   * @returns void
   */
  handleFileAdd(
    file: Record<string, any>,
    fileList: any[],
    option: STEP_OPTION_ITEM_CONFIG
  ): void {
    option.fileList = fileList;
    (this.$refs.ruleForm as any).validateField(option._name);
    (this.$refs.ruleForm as any).validate(option._name);
  }

  /**
   * change the option.fileList value to empty when deleting a file
   * @param {any} file: default parameter, the current deleted file
   * @param {any} fileList: default paramter, the uploaded file list
   * @param {any} option: current option object (file option)
   * @returns void
   */
  handleFileRemove(
    file: Record<string, any>,
    fileList: any[],
    option: STEP_OPTION_ITEM_CONFIG
  ): void {
    option.fileList = [];
    (this.$refs.ruleForm as any).validateField(option._name);
  }

  /**
   *  Check the validation of the parameter form
   *  Used by parent component
   * @returns {boolean} true: pass the validation, false: failed the validation
   */
  validateParamForm(): boolean {
    if (!this.$refs.ruleForm) {
      return true;
    }
    let passFlag = true;
    (this.$refs.ruleForm as any).validate((valid) => {
      if (!valid) {
        passFlag = false;
      }
    });
    return passFlag;
  }

  /**
   * Displays date component type daterange or datetimerange
   * @param {string} dateformat: date format
   * @param {string} type: The type of data to be returned
   * @returns {string} date range
   */
  isShowTime(dateformat: string, type: string): any {
    let isTime = false;
    let dateComponentType = "";
    for (let i = 0; i < this.dateTypeFormat.length; i++) {
      if (dateformat.indexOf(this.dateTypeFormat[i]) > -1) {
        isTime = true;
        break;
      }
    }
    if (dateformat.indexOf("(range)") > -1) {
      dateComponentType = isTime ? "datetimerange" : "daterange";
    } else {
      dateComponentType = isTime ? "datetime" : "date";
    }
    return this.dateComponentInfo[dateComponentType][type];
  }

  remoteSearchMethod(query: string, index: number): void {
    //document.getElementsByClassName('el-select-dropdown__wrap')[0].scrollTop = 0;
    //document.getElementsByClassName('el-scrollbar__thumb')[1].style.transform = "translateY(0)";
    this.loading = false;
    if (query?.length > 2) {
      this.loading = true;
      this.multiselectOffset = 0;
      this.searchOptionsArray = [];
      this.searchStrObs$.next({ value: query, index });
    }
  }

  /**
   * Handles search of customer resources by search string
   * @returns void
   */

  getCustomerResourceData(): void {
    this.searchStrObs$
      .pipe(
        debounceTime(500),
        switchMap(({ value: searchStr, index }): Observable<any | null> => {
          this.searchString = searchStr;
          if (searchStr.length > 2) {
            this.multiSelectLoadingBusy = true;
            let customerResourceTypes: string[];
            customerResourceTypes = [...this.resourceType[index ?? 0]];

            const resourceTypes = customerResourceTypes.join(",");
            return from(
              tsService.getCustomerResource(
                searchStr,
                this.multiselectOffset,
                resourceTypes
              )
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe((res) => {
        if (res !== null) {
          this.multiSelectLoadingBusy = false;
          const selectOptionList =
            ParameterAreaUtils.transformResourceInfoToOptionsArray(
              res?.data?.value
            );
          if (res?.data?.count > 0) {
            const searchResult = selectOptionList.filter((item) => {
              return item.label
                .toLowerCase()
                .includes(this.searchString.toLowerCase());
            });
            this.searchOptionsArray = [
              ...this.searchOptionsArray,
              ...searchResult,
            ];
            this.totalPages = res?.data?.totalPages;
            this.multiselectOffset += 1;
            this.multiSelectLoadingBusy = false;
          }
        }
        this.loading = false;
      });
  }

  resetOptionsArray(index: number): void {
    this.searchString = "";
    this.searchStrObs$.next({ value: "", index });
    this.multiselectOffset = this.totalPages = 0;
    this.searchOptionsArray = [];
  }

  load(index: number): void {
    if (this.multiSelectLoadingBusy) return;
    this.multiSelectLoadingBusy = true;
    if (this.multiselectOffset < this.totalPages) {
      this.searchStrObs$.next({ value: this.searchString, index });
    } else {
      this.multiSelectLoadingBusy = false;
    }
  }
}
