
import "./Steps.scss";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  WORK_ITEM_PARAMETERS,
  SUMMARY,
  STEP_ITEM,
  STEP_STATUS,
  PRODUCT_NAME,
  STEP_TYPE,
  SCHEDULE_TYPE,
  WORKITEM_JOB_TYPE,
  SUBMIT_WORKITEM_OBJECT,
  SUBMIT_SCHEDULE_WORKITEM_OBJECT,
  SUBMIT_DATA_FROM_SCHEDULE_POPUP,
  SCHEDULE_FORM_DATA,
  GET_ALL_JOB_PARAMS,
  GET_JOB_DEFINITION_PARAMS,
  CLOUD_PRODUCT_TYPE,
  META_CONFIG,
} from "@/shared/interface/newItem";
import ParameterAreaComponent from "../ParameterArea/ParameterArea.vue";
import SchedulePopupComponent from "../SchedulePopup/SchedulePopup.vue";
import { tsService } from "@/shared/services/api/trouble-shooting";
import { tsGraphQLService } from "@/shared/services/api/trouble-shooting-graphQL";
import store from "@/store/index";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import lodashGet from "lodash.get";
import { Auth } from "@/shared/services/auth";
import { DEFAULT_PLACEHOLDER, StepUtils } from "@/shared/utils/StepUtils";
import { util } from "@/shared/services/util";
import { getExternalSystemNameForUser } from "@/utils";
import { EXECUTION_STATUS } from "@/shared/interface/workItemDetails";

@Component({
  name: "StepComponent",
  components: {
    ParameterAreaComponent,
    SchedulePopupComponent,
  },
})
export default class StepComponent extends Vue {
  STEP_TYPE = STEP_TYPE;
  SCHEDULE_TYPE = SCHEDULE_TYPE;
  workItemParams: WORK_ITEM_PARAMETERS = {
    product: "",
    executor: "",
    metaConfig: { email: { notify: false } },
    contentIdArray: [],
    parameters: {},
    summary: {
      text: "",
      regex: "",
    },
  };
  stepStatus = StepUtils.statusConfig;
  steps: STEP_ITEM[] = StepUtils.stepItems;
  jobs: any[] = [];
  currentContentDefinition = "";
  validateForm: any = {
    summary: {
      error: false,
      regexError: false,
      tips: "the summary can't be empty",
      regexErrorTips: (pattern) => `Doesn't match Regex ${pattern}`,
    },
  };
  submitDisabled = false;
  summaryStepIndex: number = this.steps.length - 2;
  schedulePopupVisible = false;

  public created(): void {
    let hasCloudProductAccess = false;
    this.steps.forEach((o) => {
      if (o.name !== "Submit") this.setStepStatus(o, STEP_STATUS.inProgress);
      if (o.name === "Choose Product") {
        o.labelPrefix = [CLOUD_PRODUCT_TYPE.commerce, CLOUD_PRODUCT_TYPE.c4c];
        o.valuesArray = [PRODUCT_NAME.ccv2, PRODUCT_NAME.scv2];
        o.valuesDisabled = [
          !Auth.canUserAccessCloudproduct(CLOUD_PRODUCT_TYPE.commerce),
          !Auth.canUserAccessCloudproduct(CLOUD_PRODUCT_TYPE.c4c),
        ];
        hasCloudProductAccess = o.valuesDisabled.includes(false) ? true : false;
      }
      if (o.name === "Choose Executor") {
        //make scv2 default selected, when user has only access to c4c cloud.
        let hasOnlyscv2ProductAccess =
          hasCloudProductAccess &&
          !Auth.canUserAccessCloudproduct(CLOUD_PRODUCT_TYPE.commerce);
        this.workItemParams.product = hasOnlyscv2ProductAccess
          ? PRODUCT_NAME.scv2
          : PRODUCT_NAME.ccv2;
        o.valuesArray = hasOnlyscv2ProductAccess
          ? [PRODUCT_NAME.scv2]
          : [PRODUCT_NAME.ccv2];
        o.valuesDisabled = hasCloudProductAccess ? [false] : [true];
        if (this.$route.query.clone !== undefined) {
          this.workItemParams.product = this.$route.query.product.toString();
          this.workItemParams.metaConfig.email.notify =
            this.$route.query.notify === "true";
        }
      }
    });
    if (hasCloudProductAccess) {
      this.nextStep(this.steps[0], 0, true);
      this.nextStep(this.steps[1], 1, true);
    }
  }
  /**
   * computed the current choosed content
   * @return {object} content object
   */
  get currentContent(): any {
    let matchedJob = {};
    this.jobs.forEach((project) => {
      project.children.forEach((job) => {
        if (
          this.workItemParams.contentIdArray.length > 1 &&
          job.id === this.workItemParams.contentIdArray[1]
        ) {
          matchedJob = job;
        }
      });
    });
    return matchedJob;
  }

  get jobfromRunbookName(): any {
    let matchedJob = {};
    for (const project of this.jobs) {
      matchedJob = project.children.find(
        (job) => job.jobName === this.$route.query.runbook
      );
      if (matchedJob) {
        break;
      }
    }
    return matchedJob;
  }

  @Watch("workItemParams.summary", { deep: true, immediate: true })
  OnSummaryChange(summary: SUMMARY): void {
    const val = summary.text;

    const regex = new RegExp(summary.regex);
    this.validateForm.summary.error = !val.trim();
    this.validateForm.summary.regexError = !regex.test(val);

    if (
      this.validateForm.summary.error ||
      this.validateForm.summary.regexError
    ) {
      this.setStepStatus(this.steps[this.summaryStepIndex], STEP_STATUS.failed);
      this.steps[this.steps.length - 1].disabled = true;
    } else {
      this.setStepStatus(
        this.steps[this.summaryStepIndex],
        STEP_STATUS.succeed
      );
      this.steps[this.steps.length - 1].disabled = false;
    }
  }

  @Watch("steps", { deep: true, immediate: true })
  OnStepChange(val: STEP_ITEM[]): void {
    const summaryStep = val[this.summaryStepIndex];
    if (
      summaryStep.show &&
      !this.validateForm.summary.error &&
      !this.validateForm.summary.regexError
    ) {
      this.setStepStatus(
        this.steps[this.summaryStepIndex],
        STEP_STATUS.succeed
      );
      val[val.length - 1].disabled = false;
    } else {
      val[val.length - 1].disabled = true;
    }
  }

  @Watch("jobs", { deep: true, immediate: true })
  OnJobsChange(val: any): void {
    if (this.$route.query.clone !== undefined && val.length > 0) {
      this.workItemParams.contentIdArray = [
        this.jobs.find(
          (job) => job.label === this.jobfromRunbookName.projectName
        )?.value,
        this.jobfromRunbookName.id,
      ];
      this.nextStep(this.steps[2], 2, true);
    }
  }

  /**
   * Set the status of a step
   * @param {STEP_ITEM} step: current step object
   * @param {STEP_STATUS} status: the style object of status
   * @returns void
   */
  setStepStatus(step: STEP_ITEM, status: STEP_STATUS): void {
    Object.assign(step, this.stepStatus[status]);
  }

  /**
   * clear the value of following steps when edit the step value
   * @param {string} stepName: the name of current step
   * @param {string} stepIndex: the index of current step
   * @returns void
   */
  resetWorkItemParams(stepName: string, stepIndex: number): void {
    this.steps.forEach((o, i) => {
      if (i > stepIndex && o.name !== "Submit") {
        o.show = false;
        this.setStepStatus(o, STEP_STATUS.inProgress);
        if (o.hasNextStepButton) o.showNextStepButton = true;
      }
    });
    if (stepName === "Choose Product" || stepName === "Choose Executor") {
      this.workItemParams.parameters = {};
      this.workItemParams.contentIdArray = [];
      this.workItemParams.summary.text = "";
    }
    if (stepName === "Choose Product") {
      this.workItemParams.executor = "";
    }
  }

  /**
   * Show the next step
   * Trigger by clicking  "Next" button or select a value
   * @param {STEP_ITEM} step: the current step
   * @param {number} stepIndex: the current step index
   * @param {boolean} firstLoadFlag: true -- function triggered by page refreshing, render for the first time; false -- triggered by user operation
   * @returns void
   */
  nextStep(step: STEP_ITEM, stepIndex: number, firstLoadFlag: boolean): void {
    if (!this.checkStepValid(step)) {
      return;
    }
    this.resetWorkItemParams(step.name, stepIndex);
    this.setStepStatus(step, STEP_STATUS.succeed);
    if (this.steps[stepIndex].hasNextStepButton)
      step.showNextStepButton = false;
    if (stepIndex !== this.steps.length - 2) {
      this.steps[stepIndex + 1].show = true;
    } else {
      this.steps[stepIndex + 1].disabled = false;
    }
    this.setStepDisplayData(step.name, stepIndex, firstLoadFlag);
    if (step.name === "Choose Runbook") {
      this.workItemParams.summary.text = `${this.currentContent.jobName}__${
        store.state.userName
      }__${moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")}`;
    }
  }

  /**
   * For the step need to get value remotely or compute the disaply value
   * @param {string} stepName: the current step name (trigger the data fetch of next step)
   * @param {number} stepIndex: the index of current step
   * @param {boolean} firstLoadFlag: true -- function triggered by page refreshing, render for the first time; false -- triggered by user operation
   * @returns void
   */
  setStepDisplayData(
    stepName: string,
    stepIndex: number,
    firstLoadFlag: boolean
  ): void {
    switch (stepName) {
      case "Choose Product": {
        if (this.workItemParams.product === PRODUCT_NAME.ccv2) {
          if (firstLoadFlag) this.workItemParams.executor = PRODUCT_NAME.ccv2; //both values not disabled assign ccv2 as default
          this.steps[stepIndex + 1].valuesArray = [PRODUCT_NAME.ccv2];
        } else if (this.workItemParams.product === PRODUCT_NAME.scv2) {
          if (firstLoadFlag) this.workItemParams.executor = PRODUCT_NAME.scv2;
          this.steps[stepIndex + 1].valuesArray = [PRODUCT_NAME.scv2];
        }
        break;
      }
      case "Choose Executor": {
        this.steps[stepIndex + 1].loading = true;
        this.setStepStatus(this.steps[stepIndex + 1], STEP_STATUS.loading);
        const getAllJobParams: GET_ALL_JOB_PARAMS = {
          rundeckName: [this.workItemParams.executor],
        };
        tsService
          .getAllJobs(getAllJobParams)
          .then((res) => {
            if (res.data.data)
              this.jobs = StepUtils.transformJobs(res.data.data);
            this.setStepStatus(
              this.steps[stepIndex + 1],
              STEP_STATUS.inProgress
            );
            this.steps[stepIndex + 1].loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.setStepStatus(
              this.steps[stepIndex + 1],
              STEP_STATUS.inProgress
            );
            this.steps[stepIndex + 1].loading = false;
            this.$message.error("Get contents failed, please try again.");
          });
        break;
      }
      case "Choose Runbook": {
        this.steps[stepIndex + 1].loading = true;
        this.setStepStatus(this.steps[stepIndex + 1], STEP_STATUS.loading);
        this.steps[stepIndex + 1].showNextStepButton = false;
        const currentJob = this.$route.query.clone
          ? this.jobfromRunbookName
          : this.currentContent;
        // Runbook executor may be argo or rundeck
        const params = {
          runbook_id: currentJob.jobRunDeckId,
        } as any;
        tsGraphQLService.getExecutorInfoFromRundeckId(params).then((res) => {
          const whereToRun =
            res?.data?.hybris_ccv2_rb_rundeck &&
            res?.data?.hybris_ccv2_rb_rundeck[0].where_to_run;

          const advancedSettings = JSON.parse(
            res?.data?.hybris_ccv2_rb_rundeck[0]?.advanced_settings
          );

          this.workItemParams.summary.regex =
            advancedSettings?.summary_field?.regex;

          if (
            advancedSettings?.summary_field?.placeholder &&
            advancedSettings?.summary_field?.placeholder !== ""
          ) {
            this.steps[this.summaryStepIndex].placeholder =
              advancedSettings.summary_field.placeholder;
          } else {
            this.steps[this.summaryStepIndex].placeholder = DEFAULT_PLACEHOLDER;
          }

          if (advancedSettings) {
            const defaultCheckBox =
              advancedSettings.summary_field?.default_checkbox;
            if (defaultCheckBox === false) {
              this.workItemParams.summary.text = "";
            }
          }
          if (whereToRun === "CCV2 default") {
            this.workItemParams.executor = `${this.workItemParams.product} Rundeck`;
          } else if (whereToRun === "Argo") {
            this.workItemParams.executor = `${this.workItemParams.product} Argo`;
          } else if (whereToRun === "Rundeck") {
            this.workItemParams.executor = `${this.workItemParams.product} Rundeck`;
          } else {
            this.workItemParams.executor = `${this.workItemParams.product} Argo`;
          }
        });
        const getJobDefParams: GET_JOB_DEFINITION_PARAMS = {
          id: currentJob.id,
          jobName: currentJob.jobName,
          jobRunDeckId: currentJob.jobRunDeckId,
          runDeckName: this.workItemParams.executor,
          product: this.workItemParams.product,
        };
        tsService.getJobDefinition(getJobDefParams).then(
          (res) => {
            if (res.data.data) this.currentContentDefinition = res.data.data;
            this.setStepStatus(
              this.steps[stepIndex + 1],
              STEP_STATUS.inProgress
            );
            this.steps[stepIndex + 1].loading = false;
            this.steps[stepIndex + 1].showNextStepButton = true;
          },
          (error) => {
            console.log(error);
            this.setStepStatus(
              this.steps[stepIndex + 1],
              STEP_STATUS.inProgress
            );
            this.steps[stepIndex + 1].loading = false;
            this.steps[stepIndex + 1].showNextStepButton = true;
          }
        );
        break;
      }
    }
  }

  /**
   * Chek the validation of current step before proceed to next step
   * Trigger by clicking the Next button
   * @param {STEP_ITEM} step: current step
   * @returns {boolean} true: pass the validation, false: failed to pass the validation
   */
  checkStepValid(step: STEP_ITEM): boolean {
    let passFlag = true;
    switch (step.name) {
      case "Summary":
        passFlag = !this.workItemParams.summary.text.trim() ? false : true;
        break;
      case "Provide Parameters":
        passFlag = lodashGet(
          this,
          "$refs.parameterForm[0]",
          ""
        )?.validateParamForm();
        this.validateParameterValue(step);
        break;
    }
    return passFlag;
  }
  /**
   * validate the value of parameterArea
   * @param {STEP_ITEM} step: current step
   * @returns void
   */
  validateParameterValue(step: STEP_ITEM): void {
    if (!lodashGet(this, "$refs.parameterForm[0]", "")?.validateParamForm()) {
      this.setStepStatus(step, STEP_STATUS.failed);
    } else {
      this.setStepStatus(step, STEP_STATUS.succeed);
    }
  }

  /**
   * Show the next button of the parameter step
   * trigger by child component
   * @param {string} stepName: the name of current step
   * @param {number} stepIndex: the index of current step
   * @returns void
   */
  handleParamterNextButton(stepName: string, stepIndex: number): void {
    if (
      this.steps[stepIndex].hasNextStepButton &&
      !this.steps[stepIndex].showNextStepButton
    ) {
      this.resetWorkItemParams(stepName, stepIndex);
      this.steps[this.steps.length - 1].disabled = true;
    }
    this.steps[stepIndex].showNextStepButton = true;
  }

  /**
   * Submit the work item form
   * Save the work item to database and call execution API here
   * @param {SCHEDULE_TYPE} scheduleType: if the work item is scheduled or not
   * @param {SCHEDULE_FORM_DATA} scheuledFormData: the form data comes from scheduled Pop-up
   * @returns Promise<void>
   */
  async Submit(
    scheduleType: SCHEDULE_TYPE,
    scheduledFormData: SCHEDULE_FORM_DATA
  ): Promise<void> {
    this.setStepStatus(this.steps[this.steps.length - 1], STEP_STATUS.succeed);
    this.submitDisabled = true;
    let parameters: any[] = [];
    let parameterSource = lodashGet(this, "$refs.parameterForm[0]", "")
      ?.paramForm.optionArray;
    for (const item of parameterSource) {
      parameters.push(await StepUtils.createParameterJSON(item));
    }
    const submitObject: SUBMIT_WORKITEM_OBJECT = this.combineDataBeforeSubmit(
      scheduleType,
      parameters,
      scheduledFormData
    );
    await this.submitWorkItem(scheduleType, submitObject);
  }

  /**
   * Call GraphQl to insert case information into the database
   * @param {SCHEDULE_TYPE} scheduleType: Once/Periodically
   * @param {JSON} submitObject: submit data
   * @returns void
   */
  async submitWorkItem(
    scheduleType: SCHEDULE_TYPE,
    submitObject: SUBMIT_WORKITEM_OBJECT
  ): Promise<void> {
    const [err, res] = await util.flattenPromise(
      tsGraphQLService.createWorkItem(submitObject)
    );
    if (err) {
      this.$message.error("Create work item failed.");
      console.log(err);
      this.showSchedulePopup(false);
      this.submitDisabled = false;
      return;
    }

    const data = res.data.insert_hybris_workspace_item.returning;
    if (!data || !data.length) {
      return;
    }
    if (
      scheduleType !== SCHEDULE_TYPE.once &&
      scheduleType !== SCHEDULE_TYPE.periodically
    ) {
      this.$message.success(
        `Work item ${submitObject.summary} was created successfully`
      );
    }

    const options = JSON.parse(submitObject.parameters);
    for (const t of options) {
      if (t._name === "WORKITEM_ID") {
        t.value = `${data[0].id}`;
        break;
      }
    }

    const [err1] = await util.flattenPromise(
      tsGraphQLService.updateWorkItem({
        set: {
          parameters: JSON.stringify(options),
        },
        id: data[0].id,
      })
    );

    if (err1) {
      const errorText = err1?.response?.statusText || "error";
      this.handleRunCaseError(scheduleType, data, errorText, true);
      return;
    }

    const [error, result] = await util.flattenPromise(
      tsService.runCase({
        scheduleType: scheduleType,
        id: data[0].id,
        name: store.state.currentApp.name,
      })
    );

    if (error) {
      const errorText = error?.response?.statusText || "error";
      this.handleRunCaseError(scheduleType, data, errorText, true);
      return;
    }
    const resData = result.data;
    const msg = resData.msg || "";

    const owner = getExternalSystemNameForUser(submitObject.owner);
    if (owner === "IA") {
      // if owner is IA system don't call any callback API.
      return;
    }
    if (resData.code === "1") {
      this.handleRunCaseSuccess(scheduleType, data, msg);
    } else {
      this.handleRunCaseError(scheduleType, data, msg, false);
    }
  }

  handleDisable(stepName: string): boolean {
    if (stepName === "Choose Runbook" && this.$route?.query?.clone === "true") {
      return true;
    }
    return this.submitDisabled;
  }

  /**
   * When submitting work item failed, need to:
   * Ondemand Job: update status in hybirs_workspace_item
   * Schedule Job: update status in cx_iops_job_schedule
   * @param {SCHEDULE_TYPE} scheduleType: Once/Periodically
   * @param {Array} data: the returning date of create workItem
   * @param {string} errorMsg: the error message from API
   * @param {boolean} updateDBFlag: true—— need to update the status in DB, false—— needn't update the DB status
   * @returns void
   */
  handleRunCaseError(
    scheduleType: SCHEDULE_TYPE,
    data: any[],
    errorMsg: string,
    updateDBFlag: boolean
  ): void {
    let msg = "";
    if (
      scheduleType === SCHEDULE_TYPE.once ||
      scheduleType === SCHEDULE_TYPE.periodically
    ) {
      if (updateDBFlag)
        tsGraphQLService.updateScheduleWorkItemStatus(data[0].schedule_id);
      this.submitDisabled = false;
      this.showSchedulePopup(false);
      msg = "Can’t be able to schedule the work item due to ";
    } else {
      if (updateDBFlag) {
        this.updateOndemandExecution(data);
      } else {
        this.$router.push("/itemlist");
      }
    }
    this.$message({
      message: msg + errorMsg,
      type: "error",
      duration: 6000,
    });
  }

  /**
   * handle submitting work item succeed
   * @param {SCHEDULE_TYPE} scheduleType: Once/Periodically
   * @param {Array} data: the returning date of create workItem
   * @param {string} successMsg: the success message from API
   * @returns void
   */
  handleRunCaseSuccess(
    scheduleType: SCHEDULE_TYPE,
    data: any[],
    successMsg: string
  ): void {
    if (
      scheduleType === SCHEDULE_TYPE.once ||
      scheduleType === SCHEDULE_TYPE.periodically
    ) {
      this.$message({
        message: "Work item scheduled successfully. " + successMsg,
        type: "success",
        duration: 6000,
      });
      this["$router"].push(`/scheduledlist?scheduleType=${scheduleType}`);
    } else {
      this["$router"].push(`/detail?id=${data[0].id}`);
    }
  }
  /*
   * @param {SCHEDULE_TYPE} scheduleType: Once/Periodically
   * @param {Array} data: the returning date of create workItem
   * @returns void
   */
  updateOndemandExecution(data: any[]): void {
    tsGraphQLService.setExecutionRecords(data[0].id).finally(() => {
      this.$router.push("/itemlist");
    });
  }

  /**
   * Open the schedule Pop-up
   * @param {boolean} flag: true —— open the schedule Pop-up, false —— close the schedule Pop-up
   * @returns void
   */
  showSchedulePopup(flag: boolean): void {
    this.schedulePopupVisible = flag;
  }

  /**
   * Triggered by schedule Pop-up component
   * @param {SUBMIT_DATA_FROM_SCHEDULE_POPUP} data: data from the scheduled Pop-up
   * @returns void
   */
  triggerSubmit(data: SUBMIT_DATA_FROM_SCHEDULE_POPUP): void {
    this.Submit(data.scheduleType, data.formData);
  }

  /**
   * combine the parameters before submitting
   * @param {SCHEDULE_TYPE} scheduleType: Once/Periodically
   * @param {any[]} parameters: the value of parameter step
   * @param {SCHEDULE_FORM_DATA} scheuledFormData: the data comes from the scheduled Pop-up form
   * @returns SUBMIT_WORKITEM_OBJECT
   */
  combineDataBeforeSubmit(
    scheduleType: SCHEDULE_TYPE,
    parameters: any[],
    scheduledFormData: SCHEDULE_FORM_DATA
  ): SUBMIT_WORKITEM_OBJECT {
    let metaConfig: META_CONFIG = this.workItemParams.metaConfig;
    if (metaConfig.email.notify === true) {
      metaConfig.email.events = [
        EXECUTION_STATUS.submitFailed,
        EXECUTION_STATUS.failed,
      ];
      metaConfig.email.emailAddress = store.state.userEmail;
    }
    const consumer_roles = store.state.userRoles.filter((it) =>
      process.env.VUE_APP_IOPS_APIS_ROLES?.includes(it)
    );
    metaConfig.consumer_roles = consumer_roles;
    let submitWorkItemObject: SUBMIT_WORKITEM_OBJECT = {
      owner: store.state.userId,
      created_by: store.state.userId,
      summary: this.workItemParams.summary.text,
      product: this.workItemParams.product,
      executor: this.workItemParams.executor,
      meta_config: JSON.stringify(metaConfig),
      parameters: JSON.stringify(parameters),
      app_name: store.state.currentApp.name,
      schedule_type: scheduleType,
      job_type:
        scheduleType === SCHEDULE_TYPE.once ||
        scheduleType === SCHEDULE_TYPE.periodically
          ? WORKITEM_JOB_TYPE.scheduled
          : WORKITEM_JOB_TYPE.ondemand,
      hybris_workspace_item_jobs: {
        data: {
          job_id: this.workItemParams.contentIdArray[1],
        },
      },
    };
    if (
      scheduleType === SCHEDULE_TYPE.once ||
      scheduleType === SCHEDULE_TYPE.periodically
    ) {
      const uuid = uuidv4();
      let submitScheduleWorkItemObject: SUBMIT_SCHEDULE_WORKITEM_OBJECT = {
        cx_iops_job_schedule: {
          data: {
            schedule_id: uuid,
            created_by: store.state.userId,
            user_email: store.state.userEmail,
            start_time: moment.utc(new Date()).format().toString(),
          },
        },
      };
      if (scheduleType === SCHEDULE_TYPE.once) {
        const start_time = scheduledFormData.startTime || "";
        submitScheduleWorkItemObject.cx_iops_job_schedule.data.start_time =
          start_time.toString();
      }
      if (scheduleType === SCHEDULE_TYPE.periodically) {
        submitScheduleWorkItemObject.cx_iops_job_schedule.data.cron_expression =
          scheduledFormData.cronExpression;
      }
      submitWorkItemObject = {
        ...submitWorkItemObject,
        ...submitScheduleWorkItemObject,
      };
    }
    return submitWorkItemObject;
  }

  /**
   * filter the value of step type cascader
   * @param {Object} node: the Node of cascader
   * @param {string} keyword: the filter criteria
   * @returns boolean
   */
  filterCasecader(node: Record<string, any>, keyword: string): boolean {
    const NodeLabel = node?.data?.label?.toLowerCase() || "";
    const keywordValue = keyword.toLowerCase() || "";
    return NodeLabel.includes(keywordValue);
  }
}
